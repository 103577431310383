import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as AOS from 'aos';

@Component({
  selector: 'app-quiz-start',
  templateUrl: './quiz-start.component.html',
  styleUrls: ['./quiz-start.component.scss']
})
export class QuizStartComponent implements OnInit {

  @Output() startQuiz   = new EventEmitter();
  @Input() preparingQuiz:boolean;
  @Input() isSubmitting:boolean;
  

  constructor() {
    AOS.init();
  }

  ngOnInit(): void {
  }

}
