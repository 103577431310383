import { OnInit, Component } from '@angular/core';

import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  // Hide header
  showheader                  = false;

  // Public URLs like login
  publicUrl                   = ['/login', '/signup'];

  public constructor(
    private router: Router
  ) {

  }

  ngOnInit() {
    // If this is a public page remove the header
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd)
      ).subscribe(data => {

        if( this.publicUrl.includes( data['url'] ) ) {
          this.showheader     = false;
        } else {
          this.showheader     = true;
        }

      });
  }

}
