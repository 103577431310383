import { Routes, RouterModule } from "@angular/router";

// App Component.
// -------------
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { QuizComponent } from "./quiz/quiz.component";
import { LogoutComponent } from "./logout/logout.component";

export const router: Routes = [

    { path: '',  redirectTo: 'quiz', pathMatch: 'full' },
    { path: 'quiz', component: QuizComponent },
    { path: 'logout', component: LogoutComponent },
    { path: '**', component: PageNotFoundComponent }

];

export const routes = RouterModule.forRoot(router, { relativeLinkResolution: 'legacy' });
