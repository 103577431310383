<!-- main quiz wrapper -->
<main class="quiz-wrapper" *ngIf="!isLoading">

  <!-- quiz start screen -->
  <app-quiz-start (startQuiz)="startQuiz();" [isSubmitting]="isSubmitting" [preparingQuiz]="preparingQuiz" class="quiz-block filter-blur" id="start" [class.visible]="startBlock"></app-quiz-start>

  <!-- quiz form -->
  <div class="quiz-form-wrapper">
    <div class="frame">

      <!-- quiz stepper -->
      <div class="quiz-stepper" data-aos-once="true">

        <mat-horizontal-stepper #stepper [disableRipple]="true" class="{{ 'last-edited-step-' + maxSelectedIndex }}">

          <mat-step>
            <ng-template matStepLabel>
              <span class="mat-stepper-click" (click)="moveToNextBlock(1)" ></span>
              <!-- <span class="tool-tip" >QUESTION1</span> -->
            </ng-template>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel>
              <span class="mat-stepper-click" (click)="moveToNextBlock(2)"></span>
              <!-- <span class="tool-tip" >QUESTION2</span> -->
             </ng-template>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel>
              <span class="mat-stepper-click" (click)="moveToNextBlock(3)"></span>
              <!-- <span class="tool-tip" >QUESTION3</span> -->
            </ng-template>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel>
              <span class="mat-stepper-click" (click)="moveToNextBlock(4)"></span>
              <!-- <span class="tool-tip" >QUESTION4</span> -->
            </ng-template>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel>
              <span class="mat-stepper-click" (click)="moveToNextBlock(5)"></span>
              <!-- <span class="tool-tip" >QUESTION5</span> -->
            </ng-template>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel>
              <span class="mat-stepper-click" (click)="moveToNextBlock(6)"></span>
              <!-- <span class="tool-tip" >QUESTION6</span> -->
            </ng-template>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel>
              <span class="mat-stepper-click" (click)="moveToNextBlock(7)"></span>
              <!-- <span class="tool-tip" >QUESTION7</span> -->
            </ng-template>
          </mat-step>

          <mat-step>
           <ng-template matStepLabel>
              <span class="mat-stepper-click" (click)="moveToNextBlock(8)"></span>
              <!-- <span class="tool-tip" >QUESTION8</span> -->
           </ng-template>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel>
              <span class="mat-stepper-click" (click)="moveToNextBlock(9)"></span>
              <!-- <span class="tool-tip" >QUESTION9</span> -->
            </ng-template>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel>
              <span class="mat-stepper-click" (click)="moveToNextBlock(10)"></span>
              <!-- <span class="tool-tip" >QUESTION10</span> -->
            </ng-template>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel>
              <span class="mat-stepper-click" (click)="moveToNextBlock(11)"></span>
              <!-- <span class="tool-tip" >QUESTION11</span> -->
            </ng-template>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel>
              <span class="mat-stepper-click" (click)="moveToNextBlock(12)"></span>
              <!-- <span class="tool-tip" >QUESTION12</span> -->
            </ng-template>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel>
              <span class="mat-stepper-click" (click)="moveToNextBlock(13)"></span>
              <!-- <span class="tool-tip" >QUESTION13</span> -->
            </ng-template>
          </mat-step>

          <!-- fake stepper  -->
          <mat-step></mat-step>

        </mat-horizontal-stepper>

        <div class="step-fade-bg">
          <img src="./assets/img/fadebg.png" />
        </div>

        <div class="step-fade-bg-bottom">
          <img src="./assets/img/fadebg-bottom.png" />
        </div>

      </div>

      <!-- quiz btns -->
      <div class="quiz-navigation-btns">
        <span class="material-icons left-btn" (click)="moveToNextBlock(questionIndex - 1)" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500" *ngIf="questionIndex > 1">arrow_back</span>
        <span class="material-icons right-btn" (click)="maxSelectedIndex >= questionIndex ? moveToNextBlock(questionIndex + 1) : null" [style.pointer-events]="maxSelectedIndex >= questionIndex ? 'auto' : 'none'" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500" [class.inactive]="maxSelectedIndex < questionIndex">arrow_forward</span>
      </div>

      <!-- quiz mobile tool tip -->
      <div class="quiz-mobile-tool-tip">
        QUESTION{{ questionIndex }}
      </div>

      <!-- quiz form -->
      <form [formGroup]="quizForm" class="quiz-form">

        <!-- question 1 -->
        <!-- type - radio btns -->
        <div class="quiz-block" id="question1" [class.visible]="question1" [class.fade-out]="questionIndex == 2">
          <div class="main-heading" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
            How confident are you in your financial position at the moment?
          </div>
          <div class="quiz-radio-layout">
            <mat-radio-group formControlName="question1" (change)="validateFormField('radio', 'question1', 2, 1);">
              <mat-radio-button value="8" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200" (click)="moveToNextBlock(2)">Pretty damn confident</mat-radio-button>
              <mat-radio-button value="6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300" (click)="moveToNextBlock(2)">I could make some tweaks but I’m comfortable</mat-radio-button>
              <mat-radio-button value="4" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300" (click)="moveToNextBlock(2)">I’m low-ley stressed</mat-radio-button>
              <mat-radio-button value="2" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400" (click)="moveToNextBlock(2)">I actively avoid looking at my bank account</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <!-- question 2 -->
        <!-- type - radio btns -->
        <div class="quiz-block" id="question2" [class.visible]="question2" [class.fade-out]="questionIndex == 3">
          <div class="main-heading" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
            Where are you at with your bills and living expenses?
          </div>
          <div class="quiz-radio-layout">
            <mat-radio-group formControlName="question2" (change)="validateFormField('radio', 'question2', 3, 2)" >
              <mat-radio-button value="8" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200" (click)="moveToNextBlock(3)">I’ve got it all covered</mat-radio-button>
              <mat-radio-button value="6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300" (click)="moveToNextBlock(3)">Sometimes I’m late, I’m only human</mat-radio-button>
              <mat-radio-button value="4" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400" (click)="moveToNextBlock(3)">I’m seriously struggling to get by</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <!-- question 3 -->
        <!-- type - radio btns -->
        <div class="quiz-block" id="question3" [class.visible]="question3" [class.fade-out]="questionIndex == 4">
          <div class="main-heading" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
            Let’s talk savings. What’s the go?
          </div>
          <div class="quiz-radio-layout">
            <mat-radio-group formControlName="question3" (change)="validateFormField('radio', 'question3', 4, 3)">
              <mat-radio-button value="8" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200" (click)="moveToNextBlock(4)">I’ve got a written goal and a savings plan</mat-radio-button>
              <mat-radio-button value="6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300" (click)="moveToNextBlock(4)">I try to balance my spending and saving</mat-radio-button>
              <mat-radio-button value="4" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400" (click)="moveToNextBlock(4)">Too many expenses to save {{ smiley }}</mat-radio-button>
              <mat-radio-button value="2" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500" (click)="moveToNextBlock(4)">Got none</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <!-- question 4 -->
        <!-- type - checkbox -->
        <div class="quiz-block" id="question4" [class.visible]="question4" [class.fade-out]="questionIndex == 5">
          <div class="main-heading" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
            What accounts do you have set up (besides everyday spending)?<br />
            <div class="main-heading-small">Select all that apply.</div>
          </div>
          <div class="quiz-checkbox-layout" formGroupName="question4">
            <mat-checkbox value="2" formControlName="option1" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">Automated bills/direct debits</mat-checkbox>
            <mat-checkbox value="2" formControlName="option2" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">Savings account</mat-checkbox>
            <mat-checkbox value="4" formControlName="option3" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">Retirement fund/super</mat-checkbox>
            <mat-checkbox value="4" formControlName="option4" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">Investment/wealth account</mat-checkbox>
            <mat-checkbox value="4" formControlName="option5" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">Emergency fund</mat-checkbox>
            <button mat-button class="quiz-button voiletbtn checkbox-btn" (click)="validateFormField('checkbox', 'question4', 5, 4)" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="700">DONE</button>
          </div>
        </div>

        <!-- question 5 -->
        <!-- type - radio -->
        <div class="quiz-block" id="question5" [class.visible]="question5" [class.fade-out]="questionIndex == 6">
          <div class="main-heading" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
            What best describes your approach to spending money?
          </div>
          <div class="quiz-radio-layout">
            <mat-radio-group formControlName="question5" (change)="validateFormField('radio', 'question5', 6, 5)" >
              <mat-radio-button value="8" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200" (click)="moveToNextBlock(6)">I’ve got a sexy spreadsheet and I love sticking to it</mat-radio-button>
              <mat-radio-button value="6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300" (click)="moveToNextBlock(6)">I’ve got a rough budget, but I like to treat myself</mat-radio-button>
              <mat-radio-button value="4" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400" (click)="moveToNextBlock(6)">I like winging it</mat-radio-button>
              <mat-radio-button value="2" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600" (click)="moveToNextBlock(6)">Can’t spend what I don’t have lol</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <!-- question 6 -->
        <!-- type - radio -->
        <div class="quiz-block" id="question6" [class.visible]="question6" [class.fade-out]="questionIndex == 7">
          <div class="main-heading" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
            Got debt?
          </div>
          <div class="quiz-radio-layout">
            <mat-radio-group formControlName="question6" (change)="validateFormField('radio', 'question6', 7, 6)">
              <mat-radio-button value="8" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200" (click)="moveToNextBlock(7)">Yes but its working for me (assets etc.)</mat-radio-button>
              <mat-radio-button value="6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300" (click)="moveToNextBlock(7)">I’m a debt free homie</mat-radio-button>
              <mat-radio-button value="4" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400" (click)="moveToNextBlock(7)">Nothing I can’t handle</mat-radio-button>
              <mat-radio-button value="2" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600" (click)="moveToNextBlock(7)">I’m drowning in it</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <!-- question 7 -->
        <!-- type - radio -->
        <div class="quiz-block" id="question7" [class.visible]="question7" [class.fade-out]="questionIndex == 8">
          <div class="main-heading" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
            Thoughts on superannuation?
          </div>
          <div class="quiz-radio-layout">
            <mat-radio-group formControlName="question7" (change)="validateFormField('radio', 'question7', 8, 7)">
              <mat-radio-button value="8" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200" (click)="moveToNextBlock(8)">I’m on top of my super and making it work for me</mat-radio-button>
              <mat-radio-button value="6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300" (click)="moveToNextBlock(8)">I know I have some but I don’t have a strategy</mat-radio-button>
              <mat-radio-button value="4" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400" (click)="moveToNextBlock(8)">I couldn’t tell you how much I have or where it is</mat-radio-button>
              <mat-radio-button value="2" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500" (click)="moveToNextBlock(8)">What is super ??</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <!-- question 8 -->
        <!-- type - select -->
        <div class="quiz-block" id="question8" [class.visible]="question8" [class.fade-out]="questionIndex == 9">
          <div class="main-heading" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
            Do you invest your money?
          </div>
          <!-- <div class="quiz-select-layout">
            <mat-form-field appearance="outline" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
              <mat-select placeholder="Please select an option" formControlName="question8" (selectionChange)="validateFormField('select', 'question8', 9, 8)">
                <mat-option value="yes" (click)="moveToNextBlock(9)">Yes</mat-option>
                <mat-option value="no" (click)="moveToNextBlock(9)">No I’m lame</mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
          <div class="quiz-radio-layout">
            <mat-radio-group formControlName="question8" (change)="validateFormField('radio', 'question8', 9, 8)" >
              <mat-radio-button value="yes" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200" (click)="moveToNextBlock(9)">Yes</mat-radio-button>
              <mat-radio-button value="no" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300" (click)="moveToNextBlock(9)">No I’m lame</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <!-- question 9 -->
        <!-- type - radio -->
        <div class="quiz-block" id="question9" [class.visible]="question9" [class.fade-out]="questionIndex == 10">
          <div class="main-heading" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
            How much are you vibing your job right now?
          </div>
          <div class="quiz-radio-layout">
            <mat-radio-group formControlName="question9" (change)="validateFormField('radio', 'question9', 10, 9)">
              <mat-radio-button value="8" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200" (click)="moveToNextBlock(10)">It’s my dream job</mat-radio-button>
              <mat-radio-button value="6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300" (click)="moveToNextBlock(10)">It’s good for where I’m at</mat-radio-button>
              <mat-radio-button value="4" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400" (click)="moveToNextBlock(10)">It’s alright</mat-radio-button>
              <mat-radio-button value="2" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500" (click)="moveToNextBlock(10)">Work sucks</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <!-- question 10 -->
        <!-- type - radio -->
        <div class="quiz-block" id="question10" [class.visible]="question10" [class.fade-out]="questionIndex == 11">
          <div class="main-heading" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
            If I lost my job tomorrow...
          </div>
          <div class="quiz-radio-layout quiz-radio-layout-800">
            <mat-radio-group formControlName="question10" (change)="validateFormField('radio', 'question10', 11, 10)">
              <mat-radio-button value="8" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200" (click)="moveToNextBlock(11)">I could cruise on my other sources of income</mat-radio-button>
              <mat-radio-button value="6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300" (click)="moveToNextBlock(11)">My emergency account/savings would support me for 6 months</mat-radio-button>
              <mat-radio-button value="4" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400" (click)="moveToNextBlock(11)">I could make it 3 months if I cancelled my 6 streaming subscriptions</mat-radio-button>
              <mat-radio-button value="2" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500" (click)="moveToNextBlock(11)">I’d be screwed</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <!-- question 11 -->
        <!-- type - radio -->
        <div class="quiz-block" id="question11" [class.visible]="question11" [class.fade-out]="questionIndex == 12">
          <div class="main-heading" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
            If someone asked you where you want to be financially in 10 years...
          </div>
          <div class="quiz-radio-layout quiz-radio-layout-700">
            <mat-radio-group formControlName="question11" (change)="validateFormField('radio', 'question11', 12, 11)">
              <mat-radio-button value="8" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200" (click)="moveToNextBlock(12)">I would show them my 12-step plan to financial freedom</mat-radio-button>
              <mat-radio-button value="6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300" (click)="moveToNextBlock(12)">I could tell them my goals</mat-radio-button>
              <mat-radio-button value="4" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400" (click)="moveToNextBlock(12)">I would probably have a rough idea</mat-radio-button>
              <mat-radio-button value="2" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500" (click)="moveToNextBlock(12)">I would stare at them blankly</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <!-- question 12 -->
        <!-- type - radio -->
        <div class="quiz-block" id="question12" [class.visible]="question12" [class.fade-out]="questionIndex == 13">
          <div class="main-heading" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
            How important is getting your shit together to you?
          </div>
          <div class="quiz-radio-layout">
            <mat-radio-group formControlName="question12" (change)="validateFormField('radio', 'question12', 13, 12)">
              <mat-radio-button value="8" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200" (click)="moveToNextBlock(13)">I want to thriveeeee</mat-radio-button>
              <mat-radio-button value="6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300" (click)="moveToNextBlock(13)">It would be nice to be on top of it</mat-radio-button>
              <mat-radio-button value="4" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400" (click)="moveToNextBlock(13)">I don’t really care</mat-radio-button>
              <mat-radio-button value="2" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500" (click)="moveToNextBlock(13)">Shit is my happy place</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <!-- question 13 -->
        <!-- type - input -->
        <div class="quiz-block" id="question13" [class.visible]="question13">
          <div class="main-heading" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
            Leave your deets so we can send you the full gyst.
          </div>
          <div class="quiz-input-layout">
            <input *ngIf="!askPassword" formControlName="firstName" placeholder="First Name" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
            <input *ngIf="!askPassword" formControlName="lastName" placeholder="Last Name" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
            <input formControlName="email" placeholder="Email" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">
            <div *ngIf="askPassword" data-aos="fade-up" data-aos-duration="1000">
              You already have an account with Purplepanda.<br>
              Please login with your password below. <a style="cursor: pointer" (click)="removePasswordField()">Use a different email</a>
              <input *ngIf="askPassword" formControlName="password" type="password" placeholder="Password">
            </div>
            <button mat-button class="quiz-button voiletbtn input-btn" (click)="submitForm()" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600" [class.loading]="isSubmittingForm">
              <img class="submit-loader" *ngIf="isSubmittingForm" src="../assets/img/submit-loader.gif" />
              <span *ngIf="!isSubmittingForm">Hit me up</span>
            </button>
          </div>
        </div>

        <!-- question 14 -->
        <!-- type - input -->
        <div class="quiz-block" id="question14" [class.visible]="question14">
          <div class="main-heading" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100" style="margin-bottom: 20px;" [innerHTML]="resultTitle"></div>
          <div class="quiz-input-layout">
            <div class="secondary-heading" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100" style="margin-bottom: 20px;" [innerHTML]="resultParagraph"></div>
            <button mat-button class="quiz-button voiletbtn input-btn" (click)="completeQuiz()" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400" [class.loading]="isSubmittingForm">
              <img class="submit-loader" *ngIf="isSubmittingForm" src="../assets/img/submit-loader.gif" />
              <span *ngIf="!isSubmittingForm" [innerHTML]="resultBtnTxt"></span>
            </button>
          </div>
        </div>

      </form>

    </div><!-- frame -->
  </div><!-- quiz-main-form -->

</main>
