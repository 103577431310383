import { HostListener, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';

import { FormGroup, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';

import { UiService } from '../shared/ui.service';
import { ApiService } from '../shared/api.service';
import { AuthService } from '../shared/auth.service';

import { environment } from '../../environments/environment';

import * as AOS from 'aos';


@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {

  // booleans
  isLoading                             = true;
  preparingQuiz                         = false;
  isClickOccured                        = false;

  //hide & show booleans
  startBlock                            = true;
  question1                             = false;
  question2                             = false;
  question3                             = false;
  question4                             = false;
  question5                             = false;
  question6                             = false;
  question7                             = false;
  question8                             = false;
  question9                             = false;
  question10                            = false;
  question11                            = false;
  question12                            = false;
  question13                            = false;

  isSubmitting                          = false;
  isSubmittingForm                      = false;

  askPassword                           = false;

  resultTitle                           = "";
  resultParagraph                       = "";
  resultBtnTxt                          = "Let's GYST";
  smiley                                = "😟";

  userId: number;
  userUniqueId: string;

  // form
  quizForm:FormGroup;

  //stepper
  @ViewChild('stepper', { static: false }) stepper : MatStepper;
  maxSelectedIndex                      = 0;

  // payload with db ids as in quizzes_fields table
  payload                               = {
    fields: {
      '1': null,
      '2': null,
      '3': null,
      '4': null,
      '5': null,
      '6': null,
      '7': null,
      '8': null,
      '9': null,
      '10': null,
      '11': null,
      '12': null,
    },
    scores: {
      '1': null,
      '2': null,
      '3': null,
      '4': null,
      '5': null,
      '6': null,
      '7': null,
      '8': null,
      '9': null,
      '10': null,
      '11': null,
      '12': null,
    },
    'firstName' : null,
    'lastName' : null,
    'email' : null,
    'password' : null,
    'score' : null
  };

  questionIndex:number                  = 0;
  prevQuestionIndex:number              = 0;
  totalQuestions                        = 14;

  @HostListener('wheel', ['$event'])
  onScroll(event) {
    const activeElem = Math.floor(window.pageYOffset/ document.documentElement.clientHeight);
    if(activeElem && activeElem != this.questionIndex && this.maxSelectedIndex >= activeElem) {
      this.questionIndex                = activeElem;
      this.moveStepper(this.questionIndex , this.stepper);
    }
  }

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private ui: UiService,
    private auth: AuthService
  ) {
      AOS.init({
        once: false
      });
   }

  ngOnInit(): void {
    document.body.style.overflow = 'hidden';

    // quiz form
    this.quizForm = this.fb.group({
      'question1'       : ['', [Validators.required]], // radio
      'question2'       : ['', [Validators.required]], // radio
      'question3'       : ['', [Validators.required]], // radio
      'question4'       : this.fb.group({              // checkbox
        'option1'       : [false],
        'option2'       : [false],
        'option3'       : [false],
        'option4'       : [false],
        'option5'       : [false],
      }),
      'question5'       : ['', [Validators.required]], // radio
      'question6'       : ['', [Validators.required]], // radio
      'question7'       : ['', [Validators.required]], // radio
      'question8'       : ['', [Validators.required]], // select
      'question9'       : ['', [Validators.required]], // radio
      'question10'      : ['', [Validators.required]], // radio
      'question11'      : ['', [Validators.required]], // radio
      'question12'      : ['', [Validators.required]], // radio
      'firstName'       : ['', [Validators.required]], // input
      'lastName'        : ['', [Validators.required]], // input
      'email'           : ['', [Validators.required, Validators.email]], // input
      'password'        : [''], // input
    });

    // if user logged in then load data from db
    if(this.auth.isLoggedIn() && this.auth.getUserId()) {

      this.api.get('v1/users/' + this.auth.getUserId() + '/validateQuizSession')
        .subscribe(res => {
          if(res.status == "authorized") {

            this.api.get('v1/quiz/user/' + this.auth.getUserId())
              .subscribe(data => {
                console.log(data);
                if(data.status == "success") {

                  let question1 = data.data.find(function (obj) { return obj.field_id == "1" });
                  if(question1) {
                    this.quizForm.controls.question1.setValue(question1.value);
                    this.maxSelectedIndex     = 1;
                    this.payload.fields['1']  = question1.value;
                  }

                  let question2 = data.data.find(function (obj) { return obj.field_id == "2" });
                  if(question2) {
                    this.quizForm.controls.question2.setValue(question2.value);
                    this.maxSelectedIndex     = 2;
                    this.payload.fields['2']  = question2.value;
                  }

                  let question3 = data.data.find(function (obj) { return obj.field_id == "3" });
                  if(question3) {
                    this.quizForm.controls.question3.setValue(question3.value);
                    this.maxSelectedIndex     = 3;
                    this.payload.fields['3']  = question3.value;
                  }

                  let question4 = data.data.find(function (obj) { return obj.field_id == "4" });
                  if(question4) {
                    this.maxSelectedIndex     = 4;
                    let values                = question4.value.split(",");
                    this.payload.fields['4']  = question4.value;
                    this.quizForm.get('question4.option1').setValue(JSON.parse(values[0]));
                    this.quizForm.get('question4.option2').setValue(JSON.parse(values[1]));
                    this.quizForm.get('question4.option3').setValue(JSON.parse(values[2]));
                    this.quizForm.get('question4.option4').setValue(JSON.parse(values[3]));
                    this.quizForm.get('question4.option5').setValue(JSON.parse(values[4]));
                  }

                  let question5 = data.data.find(function (obj) { return obj.field_id == "5" });
                  if(question5) {
                    this.quizForm.controls.question5.setValue(question5.value);
                    this.maxSelectedIndex     = 5;
                    this.payload.fields['5']  = question5.value;
                  }

                  let question6 = data.data.find(function (obj) { return obj.field_id == "6" });
                  if(question6) {
                    this.quizForm.controls.question6.setValue(question6.value);
                    this.maxSelectedIndex     = 6;
                    this.payload.fields['6']  = question6.value;
                  }

                  let question7 = data.data.find(function (obj) { return obj.field_id == "7" });
                  if(question7) {
                    this.quizForm.controls.question7.setValue(question7.value);
                    this.maxSelectedIndex     = 7;
                    this.payload.fields['7']  = question7.value;
                  }

                  let question8 = data.data.find(function (obj) { return obj.field_id == "8" });
                  if(question8) {
                    this.quizForm.controls.question8.setValue(question8.value);
                    this.maxSelectedIndex     = 8;
                    this.payload.fields['8']  = question8.value;
                  }

                  let question9 = data.data.find(function (obj) { return obj.field_id == "9" });
                  if(question9) {
                    this.quizForm.controls.question9.setValue(question9.value);
                    this.maxSelectedIndex     = 9;
                    this.payload.fields['9']  = question9.value;
                  }

                  let question10 = data.data.find(function (obj) { return obj.field_id == "10" });
                  if(question10) {
                    this.quizForm.controls.question10.setValue(question10.value);
                    this.maxSelectedIndex     = 10;
                    this.payload.fields['10']  = question10.value;
                  }

                  let question11 = data.data.find(function (obj) { return obj.field_id == "11" });
                  if(question11) {
                    this.quizForm.controls.question11.setValue(question11.value);
                    this.maxSelectedIndex     = 11;
                    this.payload.fields['11']  = question11.value;
                  }

                  let question12 = data.data.find(function (obj) { return obj.field_id == "12" });
                  if(question12) {
                    this.quizForm.controls.question12.setValue(question12.value);
                    this.maxSelectedIndex     = 12;
                    this.payload.fields['12']  = question12.value;
                  }

                  this.isLoading            = false;
                  document.querySelector('.quiz-spinner').classList.add('trans-out');
                } else if(data.status == "notExists") {
                  this.auth.logout();
                  this.isLoading            = false;
                  document.querySelector('.quiz-spinner').classList.add('trans-out');
                } else {
                  this.ui.showSnackbar("Oops, something went wrong.(Error No: 0004)", "failure");
                }
              }, err => {
                this.ui.showSnackbar("Network error, please check the network and do it again.(Error No: 0005)", "failure");
              });
          } else {
            this.auth.logout();
            this.ngOnInit();
          }
        }, err => {
          this.auth.logout();
          this.ngOnInit();
        });


    } else {
      document.querySelector('.quiz-spinner').classList.add('trans-out');
      this.isLoading                  = false;
      console.log(this.startBlock);
      console.log(this.questionIndex);
    }
  }

  //  validate form field - if user submitted form field, only then move to next field
  validateFormField(type, fc, id, dbId?) {

    // type - type of field - radio/select/checkbox
    // fc - form controlname
    // id - scroll to id
    // dbId - db id in quizzes_fields, optional field in case of inputArray

    // type radio or select
    if(type == 'radio' || type == 'select') {
      let value = this.quizForm.controls[fc].value;
      if(value) {
        this.moveToNextBlock(id);
        this.submitFormField(dbId, value);
      }
    }

    // type checkbox
    if(type == 'checkbox') {
      // let count  = 0;
      let value  = this.quizForm.controls[fc].value;
      let values = [];
      Object.keys(value).forEach(key => {
        values.push(this.quizForm.controls[fc].value[key]);
        // check atleast one checkbox is checked
        // if(this.quizForm.controls[fc].value[key]) {
        //   count++;
        // }
      });
       // check atleast one checkbox is checked
      // if(count > 0) {
        this.moveToNextBlock(id);
        this.submitFormField(dbId, values.join(','));
      // }
    }

    // type inputArray
    // if(type == 'inputArray') {
    //   let count = 0;
    //   for(let i = 0; i<fc.length; i++) {
    //     // check all input fields containing values
    //     if(this.quizForm.controls[fc[i]].value) {
    //       count++;
    //     }
    //   }
    //   if(count === fc.length) {
    //     this.moveToNextBlock(id);
    //   }
    // }

  }

  // submit form field value to the db
  submitFormField(id, value) {

    const payload         = {
      field: id,
      value: value
    };

    this.payload.fields[id]      = value;

    console.log(payload);
    this.api.put('v1/quiz/user/' + this.auth.getUserId() + '/update', payload)
      .subscribe(data => {
        console.log(data);
    });

  }

  // start the quiz
  startQuiz() {
    // if user logged in, then move to next block, otherwise register new user as lead user
    this.preparingQuiz                = true;
    this.isSubmitting                 = true;
    document.body.style.overflow      = 'unset';

    if(this.auth.isLoggedIn()) {
      this.moveToNextBlock(1);
      this.preparingQuiz              = false;
      this.isSubmitting               = false;
    } else {
      const payload = {};
      this.api.post('v1/quiz/register', payload)
        .subscribe(data => {
          console.log(data);
          if(data.status == "success") {
            this.preparingQuiz        = false;
            this.isSubmitting         = false;
            this.moveToNextBlock(1);
            this.auth.setToken(data.token);
            this.auth.setUserId(data.userId);
          }
      });
    }

  }

  // move form to next block
  moveToNextBlock(id) {
    // console.log(id);
    if(id >= 1 && id <= this.totalQuestions) {
      this.prevQuestionIndex    = this.questionIndex;
      this.questionIndex        = id;
      for(let i = 1; i <= id; i++) {
        this.showBlock('question' + i);
      }
      this.gotoNext('#question' + id);
      if(id != 14) {
        this.moveStepper(id, this.stepper);
      }
    }
  }

  // part of moveToNextBlock() method
  showBlock(blockBoolean) {
    this[blockBoolean]                  = true;
  }

  // part of moveToNextBlock() method, smooth scroll to next/prev block
  gotoNext(id) {
    if(!(this.questionIndex < this.prevQuestionIndex) && this.questionIndex != 0) {
      setTimeout(() => {
        //scroll
        document.querySelector(id).scrollIntoView(
          { behavior: 'smooth' }
        );

      }, 1500);
    } else {
      setTimeout(() => {
        //scroll
        document.querySelector(id).scrollIntoView(
          { behavior: 'smooth' }
        );

      }, 1);
    }
  }

  // part of moveToNextBlock() method
  // move stepper on click and also set max index to prevent users so they can't go ahead without filling field
  moveStepper(index: number, stepper: MatStepper) {
    stepper.selectedIndex = index - 1;
    if(stepper.selectedIndex > this.maxSelectedIndex) {
      this.maxSelectedIndex = stepper.selectedIndex;
    }
  }

  // make sure atleast one checkbox is checked or you can also pass your minimum required field to this function
  // requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
  //   return function validate (formGroup: FormGroup) {
  //     let checked = 0;
  //     Object.keys(formGroup.controls).forEach(key => {
  //       const control = formGroup.controls[key];
  //       if (control.value === true) {
  //         checked ++;
  //       }
  //     });

  //     if (checked < minRequired) {
  //       return {
  //         requireOneCheckboxToBeChecked: true,
  //       };
  //     }
  //     return null;
  //   };
  // }

  // submitted all form fields again on final submission and register user as an account
  submitForm() {
    // this.askPassword               = false;
    let error                      = false;
    this.isSubmittingForm          = true;
    this.payload['firstName']      = this.quizForm.controls['firstName'].value;
    this.payload['lastName']       = this.quizForm.controls['lastName'].value;
    this.payload['email']          = this.quizForm.controls['email'].value;
    this.payload['password']       = this.quizForm.controls['password'].value;

    for (const key of Object.keys(this.quizForm.controls)) {
      if (this.quizForm.controls[key].invalid) {
        error                     = true;
        if(key === 'question1') this.moveToNextBlock(1);
        if(key === 'question2') this.moveToNextBlock(2);
        if(key === 'question3') this.moveToNextBlock(3);
        if(key === 'question5') this.moveToNextBlock(5);
        if(key === 'question6') this.moveToNextBlock(6);
        if(key === 'question7') this.moveToNextBlock(7);
        if(key === 'question8') this.moveToNextBlock(8);
        if(key === 'question9') this.moveToNextBlock(9);
        if(key === 'question10') this.moveToNextBlock(10);
        if(key === 'question11') this.moveToNextBlock(11);
        if(key === 'question12') this.moveToNextBlock(12);
        break;
     }
    }

    let question4Count                = 0;
    for (var key in this.quizForm.controls['question4'].value) {
      let val = this.quizForm.controls['question4'].value[key];
      if(val) {
        question4Count++;
        break;
      }
    };
    if(question4Count === 0) {
      error                     = true;
      this.moveToNextBlock(4);
    }

    if(!error) {

      this.payload['score']           = this.calculateScore();
      this.calculateFieldScore();

      if(this.payload['score'] > 88) {
        this.resultTitle              = "Love your work " + this.payload['firstName'] + ", you’ve got your shit together.";
        this.resultParagraph          = `<p>You’re killing it. You’re motivated, organised and you know what you’re doing – congrats!
                                        It’s a pretty exciting place to be.</p>

                                        <strong>What’s next?</strong>
                                        <p>The Playground is filled with loads of courses and tools to help you thrive and
                                        really make your money work for you. With your shit together, you’re now ready to take
                                        it to the next level – keep learning, keep investing and keep strategizing so you can
                                        kick those goals of yours.</p>`;
        this.resultBtnTxt             = "Join the playground";
      } else if(this.payload['score'] > 64) {
        this.resultTitle              = "Solid stuff " + this.payload['firstName'] + ", your shit’s not bad.";
        this.resultParagraph          = `<p>You’re a go getter. You’ve got the mindset, motivation and tools in place to really
                                        up your money game. With a few tweaks you’ll be well on your way.</p>

                                        <strong>What’s next?</strong>
                                        <p>The GYST program is a 7-step course designed to help you get on top of your money – to
                                        get your shit together. From the simple stuff (like setting up an emergency account) to
                                        the spicy stuff (like making your super work for you), the GYST program will get you started.</p>
                                        <p>We reckon this would be a great place for you to start, to nail the basics and get
                                        your money working for you.</p>`;
        this.resultBtnTxt             = "Let’s GYST";
      } else if(this.payload['score'] > 48) {
        this.resultTitle              = "We’ve got some work to do, " + this.payload['firstName'] + ". Let’s get your shit together.";
        this.resultParagraph          = `<p>It looks like you’ve got some of the basics down but could do with a few changes to
                                        really up your game. You’ve got the mindset, you’ve got the tools, now it’s just time
                                        to put them into action. We’re confident you’ll be there in no time.</p>

                                        <strong>What’s next?</strong>
                                        <p>The GYST program is a 7-step course designed to help you get on top of your money – to get
                                        your shit together. From the simple stuff (like setting up an emergency account) to the
                                        spicy stuff (like making your super work for you), the GYST program will get you well
                                        on your way.</p>
                                        <p>We reckon this would be a great place for you to start, to nail the basics and get your
                                        money working for you.</p>`;
        this.resultBtnTxt             = "Let’s GYST";
      } else {
        this.resultTitle              = "It’s time to get your shit together, " + this.payload['firstName'] + ".";
        this.resultParagraph          = `<p>It looks like you’re struggling a little but that’s alright! We’re confident you’ve got
                                        the mindset and tools to get from surviving to thriving. A few tweaks to your set up and
                                        you’ll be smashing it.</p>

                                        <strong>What’s next?</strong>
                                        <p>The GYST program is 7-step course designed to help you get on top of your money – to get
                                        your shit together. From the simple stuff (like setting up an emergency account) to the
                                        spicy stuff (like making your super work for you), the GYST program will get you well
                                        on your way.</p>
                                        <p>We reckon this would be a great place for you to start, to nail the basics and get your
                                        money working for you.</p>`;
        this.resultBtnTxt             = "Let’s GYST";
      }

      if(this.askPassword) {
        console.log("here");
        this.payload['password']      = this.quizForm.controls['password'].value;
        this.payload['login']         = true;
      } else {
        this.payload['login']         = false;
      }

      console.log(this.payload);
      this.api.put('v1/quiz/user/' + this.auth.getUserId() + '/quizUpdate', this.payload)
        .subscribe(data => {
          console.log(data);
          if(this.askPassword && data.status == "success") {
            this.auth.logout();
            this.isSubmittingForm     = false;
            this.userId               = data.userId;
            window.location.href      = environment.clientUrl + '/HRmZW2/' + data.userId + '/' + data.authKey + '/' + data.authTime;
          } else if(data.status == "success") {
            this.isSubmittingForm     = false;
            this.userId               = data.userId;
            this.userUniqueId         = data.uniqueId;
            this.moveToNextBlock(14);
            // this.auth.logout();
            // window.location.href   = 'https://app.purplepanda.life/register/' + data.userId;
            // this.ui.showSnackbar("You are successfully registered", "success");
          } else if(data.status == "userexists") {
            this.isSubmittingForm     = false;
            this.askPassword          = true;
          }else {
            this.ui.showSnackbar("Oops, something went wrong.(Error No: 0006)", "failure");
          }
        }, err => {
          this.ui.showSnackbar("Network error, please check the network and do it again.(Error No: 0007)", "failure");
        });

    } else {
      this.isSubmittingForm           = false;
    }

  }

  completeQuiz() {
    this.isSubmittingForm             = true;
    this.auth.logout();
    window.location.href              = environment.clientUrl + '/register/' + this.userUniqueId;
  }

  calculateScore() {
    let score         = 0;
    let fields        = Object.keys(this.payload.fields).map((key) => [Number(key), this.payload.fields[key]]);

    for (let field of fields) {
      if(field[0] === 4) {
        let values      = field[1].split(",");
        for(let i = 0; i < values.length; i++) {
          if(Boolean(values[i])) {
            if(i <= 1) {
              score         = score + 4;
            }
            if(i >= 2 && i <= 5) {
              score         = score + 2;
            }
          }
        }
      } else if(field[0] === 8) {
        if(field[1] === "yes") {
          score         = score + 4;
        } else if(field[1] === "no"){
          score         = score + 2;
        }
      } else {
        score         = score + +field[1];
      }
    }
    return score;
  }

  calculateFieldScore() {

    let fields        = Object.keys(this.payload.fields).map((key) => {
      return { 'id': Number(key), 'value' : this.payload.fields[key]}
    });

    for (let field of fields) {
      let score         = 0;
      if(field.id === 4) {
      for(let i = 0; i < field.value.length; i++) {
        if(Boolean(field.value[i])) {
          if(i <= 1) {
              score         = score + 4;
            }
            if(i >= 2 && i <= 5) {
              score         = score + 2;
            }
          }
        }
      } else if(field.id === 8) {
        if(field.value === "yes") {
          score         = score + 4;
        } else if(field.value === "no"){
          score         = score + 2;
        }
      } else {
        score         = score + +field.value;
      }
      this.payload.scores[field.id] = score;

    }

  }

  removePasswordField() {

    this.askPassword          = false;
    this.quizForm.controls['email'].setValue('');

  }

}
